export const environment = {
  production: true,
  enableMsal: true,
  msalConfig: {
    auth: {
      clientId: 'f3574bd2-2dab-425e-ab5f-7a2413a4d0b3',
      authority: 'https://login.microsoftonline.com/ecaa386b-c8df-4ce0-ad01-740cbdb5ba55'
    }
  },
  profileApiConfig: {
    scopes: ['user.read'],
    uri: 'https://graph.microsoft.com/v1.0/me'
  },
  backendApiConfig: {
    uri: '/api/v1'
  },
};
